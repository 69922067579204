import React from 'react';
import styled from 'styled-components';

import { GlobalStyles } from '../components';

const Wrapper = styled.div`
  @media (max-width: 849px) {
    padding: 0 var(--sides-padding-mobile);
  }
`;

const Content = styled.main`
  @media (max-width: 849px) {
    width: 100%;
  }
`;

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  </>
);

export default Layout;

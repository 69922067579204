import { createGlobalStyle } from 'styled-components';

import * as font from '../fonts';

export default createGlobalStyle`
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  display: inline-block;
  font-size: inherit;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0);
  -webkit-tap-highlight-color: transparent;
  color: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  display: none;
}

/* @font-face {
  font-family: "DisplaySignature";
  src: url('${font.DisplaySignature}') format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
} */


@font-face {
  font-family: "IBMPlexMono";
  src: url('${font.IBMPlexMono400}') format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "IBMPlexMono";
  src: url('${font.IBMPlexMono700}') format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Langdon";
  src: url('${font.LangdonWoff2}') format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

body {
  line-height: default;
  --sides-padding-desktop: 3%;
  /* --sides-padding-mobile: 5%; */
  --light-color: hsl(0, 0%, 99%);
  --light-color-translucent: hsla(0, 0%, 99%, 0.92);
  --dark-color: hsl(0, 0%, 10%);
  --dark-color-light: hsla(0, 0%, 10%, 0.85);
  --accent-color: hsl(351, 100%, 63%);
  background-color: var(--background-color);
  font-family: 'Open Sans', sans-serif;
  font-size: 1.45em;
  font-weight: 400;
  color: var(--dark-color);

  @media (max-width: 849px) {
    font-size: 17px;
  }
}

h1 {
  font-family:'Langdon', sans-serif;
  font-weight: 400;
}

h2 {
    font-family: "Dancing Script", cursive;
    font-size: 1.5rem;
    font-weight: 700;
    /* margin-top:5px; */
  }

  /* h3 {
    font-family: DisplaySVG-F, sans-serif;
    font-size: 1.5rem;
    margin-bottom:5px;
  } */

  h3 {
    font-family: 'OpenSans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.6rem;
    margin-bottom:5px;
    margin-top:25px;

  }

  p {
    font-size:1.20rem;
    font-weight: 400;
    @media (max-width: 768px) {
    /* font-size:1rem; */
  }
  }

p.dropcap:first-letter {
  font-size: 3rem;
  float: left;
  margin-top: -0.15em;
  padding-right:0.2rem;
  font-family: Dancing Script, cursive;
  @media (max-width: 768px) {
    font-size:3.2rem;
    margin-top: -0.05em;
    padding-right:0.45rem;

  }
}

active {
  color:white;
}
`;

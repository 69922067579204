import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
  lang,
  title,
  description,
  author,
  twitterImage,
  facebookImage,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            twitterImage
            facebookImage
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s ― ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: `https://rocketship.productions/images/rocketship_facebook.jpg`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `https://rocketship.productions/images/rocketship_twitter.jpg`,
        },
      ]}
    />
  );
};

// SEO.defaultProps = {
//   lang: `en`,
//   meta: [],
//   description: ``,
// };

// SEO.propTypes = {
//   lang: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   author: PropTypes.string,
//   facebookImage: PropTypes.string,
//   twitterImage: PropTypes.string,
// };

export default SEO;
